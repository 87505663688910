import { AUTOMATION_PLATFORM_FLOW_TYPE_ID, CAMPAIGN_TYPE_ID, OBJECT_LIST_TYPE_ID, FEEDBACK_SUBMISSION_TYPE_ID, FORM_DASHBOARD_TYPE_ID, MARKETING_EMAIL_TYPE_ID, MARKETING_SMS_TYPE_ID, WEB_INTERACTIVE_TYPE_ID, TASK_TYPE_ID, DATA_PRIVACY_REQUEST_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import PortalIdParser from 'PortalIdParser';
const portalId = PortalIdParser.get();
export const getViewTableLink = view => {
  if (view.objectTypeId === CAMPAIGN_TYPE_ID) {
    switch (view.id) {
      default:
        return `/marketing/${portalId}/campaigns/views/${view.id}`;
      case 'my':
        return `/marketing/${portalId}/campaigns/views/all`;
      case 'uncontacted':
        return `/marketing/${portalId}/campaigns/views/recent`;
      case 'all':
        return `/marketing/${portalId}/campaigns/views/quarter`;
    }
  } else if (view.objectTypeId === OBJECT_LIST_TYPE_ID) {
    switch (view.id) {
      default:
        return `/contacts/${portalId}/objectLists/views/${view.id}`;
      case 'recently_deleted':
        return `/contacts/${portalId}/objectLists/views/deleted`;
      case 'unused':
        return `/contacts/${portalId}/objectLists/views/cleanup`;
    }
  } else if (view.objectTypeId === FEEDBACK_SUBMISSION_TYPE_ID) {
    return `/feedback/${portalId}/dashboard/manage/responses`;
  } else if (view.objectTypeId === AUTOMATION_PLATFORM_FLOW_TYPE_ID) {
    switch (view.id) {
      default:
        return `/workflows/${portalId}/view/${view.id}`;
      case 'all':
        return `/workflows/${portalId}/view/default`;
      case 'unused':
        return `/workflows/${portalId}/view/cleanup`;
      case 'recently_deleted':
        return `/workflows/${portalId}/view/deleted`;
    }
  } else if (view.objectTypeId === FORM_DASHBOARD_TYPE_ID) {
    return `/forms/${portalId}/views/${view.id}`;
  } else if (view.objectTypeId === MARKETING_SMS_TYPE_ID) {
    return `/sms/${portalId}/views/${view.id}`;
  } else if (view.objectTypeId === MARKETING_EMAIL_TYPE_ID) {
    return `/email/${portalId}/manage/state/${view.id}`;
  } else if (view.objectTypeId === WEB_INTERACTIVE_TYPE_ID) {
    switch (view.id) {
      default:
        return `/calls-to-action/${portalId}/manage/state/${view.id}`;
      case 'drafts':
        return `/calls-to-action/${portalId}/manage/state/draft`;
    }
  } else if (view.objectTypeId === TASK_TYPE_ID) {
    return `/tasks/${portalId}/view/${view.id}`;
  } else if (view.objectTypeId === DATA_PRIVACY_REQUEST_TYPE_ID) {
    return `/settings/${portalId}/data-privacy/data-privacy-requests/views/${view.id}`;
  }
  return `/contacts/${portalId}/objects/${view.objectTypeId}/views/${view.id}`;
};