import { useUserId } from '../../userInfo/hooks/useUserId';
import { isUserOwnerOfView } from '../dataOperators/isUserOwnerOfView';
import { useCanUserWriteViews } from '../../utils/hooks/useCanUserWriteViews';

/**
 * Will take in a "view" object, and return an array with two values
 * [true/false, reason]
 *
 * The first will be true or false indicating if the user has the permission to edit the view
 * The second will be an optional human readable message that can give the user an overview of why
 * or why not they can edit the view
 **/
export const useCanUserEditView = ({
  view
}) => {
  const {
    data: userId
  } = useUserId();
  const canUserWriteViews = useCanUserWriteViews();
  if (userId === undefined || view === undefined) {
    return [false, null];
  } else if (isUserOwnerOfView({
    userId,
    view
  })) {
    if (canUserWriteViews) {
      return [true, null];
    }
    return [false, 'OWNER_AND_UNPERMISSIONED'];
  }
  return [false, 'NOT_OWNER'];
};