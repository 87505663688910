import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { transformBackendViewToInternalFormat } from '../../viewsSearch/dataOperators/backendViews';
import { getGates } from '../../userInfo/utils/getGates';
import { singleViewClient } from '../../clients/singleViewClient';
import { PersistPromiseGate } from '../../userInfo/constants/Gates';
export const SINGLE_VIEW_BY_ID_QUERY_NAME = 'singleViewById';
const viewsSearchQueryArgsArgument = ['id', 'objectTypeId', 'namespaceName'];
export const SingleViewByIdQuery = registerQuery({
  fieldName: SINGLE_VIEW_BY_ID_QUERY_NAME,
  args: viewsSearchQueryArgsArgument,
  fetcher: args => {
    return getGates().then(gates => {
      if (gates.has(PersistPromiseGate)) {
        return singleViewClient.get(args);
      }
      return http.get(`/sales/v4/views/${args.objectTypeId}/${args.id}?namespace=${args.namespaceName}`, {
        data: args
      }).then(transformBackendViewToInternalFormat);
    });
  }
});
export const useFetchSingleView = query => {
  return useQuery(SingleViewByIdQuery, {
    variables: query
  });
};