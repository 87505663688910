import quickFetch from 'quick-fetch';
import { Metrics } from '../metrics/Metrics';
import { generateGetPinnedViewsUrl, generatePinnedViewsQuickFetchName } from '../pinnedViews/api/getPinnedViews';
import http from 'hub-http/clients/apiClient';
import { transformBackendViewToInternalFormat } from '../viewsSearch/dataOperators/backendViews';
import { wrapPromise } from 'persist-promise/wrapPromise';
const track = ({
  namespace,
  objectTypeId,
  startTime,
  endTime,
  usedQuickfetch
}) => {
  Metrics.histogram('pinnedViewsQueryDuration', {
    namespace,
    objectTypeId,
    usedQuickfetch: String(usedQuickfetch)
  }).update(startTime - endTime);
};
const fetch = ({
  objectTypeId,
  namespace
}) => http.get(generateGetPinnedViewsUrl({
  objectTypeId,
  namespace
}));
const fetchWithQuickFetch = ({
  objectTypeId,
  namespace
}) => {
  const requestName = generatePinnedViewsQuickFetchName({
    objectTypeId,
    namespace
  });
  const startTime = performance.now();
  const baseRequest = async () => {
    const result = await fetch({
      objectTypeId,
      namespace
    });
    const endTime = performance.now();
    track({
      namespace,
      objectTypeId,
      startTime,
      endTime,
      usedQuickfetch: false
    });
    return result;
  };
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (!earlyRequest) {
    return baseRequest();
  }
  return new Promise((resolve, reject) => {
    earlyRequest.whenFinished(result => {
      const endTime = performance.now();
      track({
        namespace,
        objectTypeId,
        startTime,
        endTime,
        usedQuickfetch: true
      });
      resolve(result);
      quickFetch.removeEarlyRequest(requestName);
    });
    earlyRequest.onError(() => {
      baseRequest().then(resolve).catch(reject);
      quickFetch.removeEarlyRequest(requestName);
    });
  });
};
const fetchWithTransformation = async ({
  objectTypeId,
  namespace
}) => {
  const result = await fetchWithQuickFetch({
    objectTypeId,
    namespace
  });
  return result.results.map(transformBackendViewToInternalFormat);
};
const pinnedViewsPersistedPromise = wrapPromise({
  namespace: 'CDVM',
  entityName: 'PinnedViews',
  toCacheKey: ({
    objectTypeId,
    namespace
  }) => `${namespace}/${objectTypeId}`,
  fetch: fetchWithTransformation,
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension: key => {
      const [namespace, objectTypeId] = key.split('/');
      return `${namespace}/${objectTypeId.startsWith('2-') ? 'customObject' : objectTypeId}`;
    },
    normalizeForPatchDiffing: (results, env) => env.keyBy(results, ({
      id
    }) => id, (view, index) => Object.assign({}, view, {
      displayOrder: index
    }))
  }
});
export const pinnedViewsClient = {
  get: pinnedViewsPersistedPromise.makeFetchWithOpts({
    refresh: true
  }),
  getWithCache: pinnedViewsPersistedPromise.makeFetchWithOpts(),
  updateCache: ({
    objectTypeId,
    namespace,
    pinnedViews
  }) => pinnedViewsPersistedPromise.syncIntoCache(Promise.resolve(pinnedViews), {
    objectTypeId,
    namespace
  })
};