import { wrapPromise } from 'persist-promise/wrapPromise';
import http from 'hub-http/clients/apiClient';
import { transformBackendViewToInternalFormat } from '../viewsSearch/dataOperators/backendViews';
export const SINGLE_VIEW_BY_ID_QUERY_NAME = 'singleViewById';
const singleViewPersistedPromise = wrapPromise({
  namespace: 'CDVM',
  entityName: 'SingleView',
  toCacheKey: ({
    objectTypeId,
    id,
    namespaceName
  }) => `${namespaceName}/${objectTypeId}/${id}`,
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension: key => {
      const [namespace, objectTypeId] = key.split('/');
      return `${namespace}/${objectTypeId.startsWith('2-') ? 'customObject' : objectTypeId}`;
    }
  },
  fetch: args => http.get(`/sales/v4/views/${args.objectTypeId}/${args.id}?namespace=${args.namespaceName}`, {
    data: args
  }).then(transformBackendViewToInternalFormat)
});
export const singleViewClient = {
  get: ({
    objectTypeId,
    id,
    namespaceName
  }) => singleViewPersistedPromise.makeFetchWithOpts({
    refresh: true
  })({
    objectTypeId,
    id,
    namespaceName
  }),
  updateCache: ({
    objectTypeId,
    id,
    namespaceName,
    view
  }) => singleViewPersistedPromise.syncIntoCache(Promise.resolve(view), {
    objectTypeId,
    id,
    namespaceName
  })
};