import userInfo from 'hub-http/userInfo';
import memoizeOne from 'react-utils/memoizeOne';
const toSet = memoizeOne(gates => new Set(gates));
const emptySet = new Set();
export const getGates = async () => {
  try {
    const userInfoResult = await userInfo({
      ignoreRedirect: true
    });
    return toSet(userInfoResult.portal.enabled_gates);
  } catch (_unused) {
    return emptySet;
  }
};