import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery, useDataFetchingClient } from 'data-fetching-client';
import { transformBackendViewToInternalFormat } from '../../viewsSearch/dataOperators/backendViews';
import { SingleViewByIdQuery } from '../../singleView/hooks/useFetchSingleView';
import { generateGetPinnedViewsUrl, generatePinnedViewsQuickFetchName } from '../api/getPinnedViews';
import { makeQuickFetchRequest } from '../../utils/makeQuickFetchRequest';
import { Metrics } from '../../metrics/Metrics';
import { pinnedViewsClient } from '../../clients/pinnedViewsClient';
import { getGates } from '../../userInfo/utils/getGates';
import { PersistPromiseGate } from '../../userInfo/constants/Gates';
export const PINNED_VIEWS_QUERY = 'pinnedViews';
export const PinnedViewsQuery = registerQuery({
  fieldName: PINNED_VIEWS_QUERY,
  args: ['objectTypeId', 'namespace'],
  fetcher: async ({
    objectTypeId,
    namespace,
    getClient
  }) => {
    let parsedResults;
    const gates = await getGates();
    if (gates.has(PersistPromiseGate)) {
      parsedResults = await pinnedViewsClient.get({
        objectTypeId,
        namespace
      });
    } else {
      const makeRequestFn = () => http.get(generateGetPinnedViewsUrl({
        objectTypeId,
        namespace
      }));
      const quickFetchRequestName = generatePinnedViewsQuickFetchName({
        objectTypeId,
        namespace
      });
      const {
        requestPromise,
        usedQuickfetch
      } = makeQuickFetchRequest({
        makeRequestFn,
        requestName: quickFetchRequestName
      });
      const startTime = performance.now();
      const result = await requestPromise;
      const endTime = performance.now();
      Metrics.histogram('pinnedViewsQueryDuration', {
        namespace,
        objectTypeId,
        usedQuickfetch: String(usedQuickfetch)
      }).update(startTime - endTime);
      parsedResults = result.results.map(transformBackendViewToInternalFormat);
    }
    const client = getClient && getClient();
    if (!client) {
      //this is for typescript safety than a runtime possibility.
      //client is the data-fetching-client "store" (not sure what you call it other than client),
      //which is also required for the `useQuery` call below in useFetchPinnedViews,
      //so you would get an error before this ever reaches here.
      //
      //Return an empty array so consumers dont have to statically check if its an array type when it will never happen at runtime
      return [];
    }
    parsedResults.forEach(view => {
      client.writeQuery({
        data: {
          singleViewById: view
        },
        query: SingleViewByIdQuery,
        variables: {
          id: view.id,
          objectTypeId: view.objectTypeId,
          namespaceName: view.namespaceName
        }
      });
    });
    return parsedResults.map(view => view.id);
  }
});
export const useFetchPinnedViews = ({
  objectTypeId,
  namespace
}) => {
  const client = useDataFetchingClient();
  return useQuery(PinnedViewsQuery, {
    variables: {
      objectTypeId,
      namespace,
      getClient: () => client
    }
  });
};