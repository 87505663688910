import { useHasScope } from '../../userInfo/hooks/useHasScope';
export const useCanUserWriteViews = () => {
  const {
    loading: scopeLoading,
    data: hasWriteViewsScope
  } = useHasScope('customize-crm-owned-views-access');
  if (scopeLoading) {
    return false;
  }
  return hasWriteViewsScope !== null && hasWriteViewsScope !== void 0 ? hasWriteViewsScope : false;
};