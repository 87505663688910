import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
//ref - https://git.hubteam.com/HubSpot/customer-data-views-management/pull/393#discussion_r2148967
//we explicitly do not use shared types here from customer-data-objects as they do not match the result from graphql
//
//For example, graphql does not return an `objectTypeId` field, yet the ObjectTypeDefinitionType from customer-data-objects indicates there is one there
//
//customer-data-views-management declares its own types for the data it fetches to prevent this bug from being reintroduced
const ObjectTypeDefinitionsQuery = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectTypeDefinitionsQuery\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allObjectTypes\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"metaTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"pluralForm\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"singularForm\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"createDatePropertyName\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"pipelinePropertyName\"}},{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"},\"name\":{\"kind\":\"Name\",\"value\":\"id\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "ObjectTypeDefinitionsQuery"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "allObjectTypes"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "metaTypeId"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "pluralForm"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "singularForm"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "createDatePropertyName"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "pipelinePropertyName"
            }
          }, {
            kind: "Field",
            alias: {
              kind: "Name",
              value: "objectTypeId"
            },
            name: {
              kind: "Name",
              value: "id"
            }
          }]
        }
      }]
    }
  }]
});
/**
 * Fetches object type definitions for each objectTypeId passed in. Returns all object type definitions if nothing is passed in.
 */
export const useFetchObjectDefinitionsByObjectTypeIds = ({
  objectTypeIds,
  skip
}) => {
  const queryResult = useQuery(ObjectTypeDefinitionsQuery, {
    skip
  });
  return useMemo(() => Object.assign({}, queryResult, {
    data: queryResult.data && queryResult.data.allObjectTypes.filter(objectType => objectTypeIds === undefined || objectTypeIds.includes(String(objectType.id)))
  }), [queryResult, objectTypeIds]);
};