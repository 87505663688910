import { useHasGate } from '../../userInfo/hooks/useHasGate';
import { gate } from 'hub-http/gates';
export const useCanGenerateDescription = () => {
  const {
    loading: ViewGateLoading,
    data: canGenerateDescription
  } = useHasGate(gate('CRM:Index:ViewDescription'));
  if (ViewGateLoading) {
    return false;
  }
  return canGenerateDescription;
};